// Imports: Dependencies
import React from 'react';
import { Helmet } from 'react-helmet';

// Imports: CSS
import '../css/MainTitle.css';
import '../css/MetricsTotals.css';
import '../css/CategoryTabs.css';
import '../css/DataSource.css';
import '../css/Footer.css';
import '../css/404.css';
import '../css/Graph.css';
import '../css/VotingFacts.css';
import '../css/VotingLinks.css';
import '../css/MainButton.css';
import '../css/USAMap.css';

// Layout
const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vote Orange County</title>
        <html lang={'en'} />
        <link rel="canonical" href="https://voteorangeCounty.com" />
        <meta name="description" content={'Orange County, CA Voting Data Dashboard'} />
      </Helmet>

      <>{children}</>
    </div>
  );
};

// Exports
export default Layout;
