// Imports: Dependencies
import React from 'react';

// Imports: Pages
import Home from './Home';

// Imports: Components (Gatsby)
import Layout from '../layouts/Layout';

// Index
const index = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  );
};

// Exports
export default index;
